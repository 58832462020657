import React from "react";
import "../css/rules.css";
import "../styleguide.css";
import {Link} from "react-router-dom";

export const Rules = () => {
    return (
        <div className="rules-page">
            <div className="div">
                <div className="rules-header">
                    <div className="section-title">House Rules</div>
                </div>
                <Link to="/nav">
                <img className="icon-back" alt="Icon back" src="back_icon.svg" />
                </Link>
                <img className="icon-home" alt="Icon home" src="icon_home.svg" />
                <p className="rules-description">
                    + Please only use the white “tub towels” when enjoying the hot tub. As the chemicals will bleach our brown
                    bath towels.
                    <br />
                    <br />+ DO NOT put grease down the garbage disposal.
                    <br />
                    <br />+ DO NOT flush feminine products down the toilets. As our septic system is sensitive.
                    <br />
                    <br />+ Please remember that this is our Home, and not a Hotel. Please treat our Home and belongings with
                    respect. We do understand accidents happen, therefore, please report any property damages to us
                    immediately&nbsp;&nbsp;so it can be addressed accordingly!
                    <br />
                    <br />
                    Thank you for respecting our Home, and enjoy your stay! Your patronage is appreciated!
                </p>
            </div>
        </div>
    );
};

export default Rules;
