import styled from "styled-components";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";

const Wrapper = styled.div`
  font-family: sans-serif;
  margin-top: 2rem;
  border-top: 1px solid #ccc;
  color: #fff;
`;

const ItemWithChevron = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <img className="chevron-down" src="chevron-down.svg" alt="Chevron Down" />
      </>
    }
  />
);

/**
 * @type {React.ExoticComponent<import('@szhsin/react-accordion').AccordionItemProps>}
 */
const AccordionItem = styled(ItemWithChevron)`
  border-bottom: 1px solid #ccc;

  .szh-accordion__item {
    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 800px; /* Increase width by 100px */
      margin: 0;
      padding: 1.5rem; /* Increase padding for taller boxes */
      font-size: 1.8rem; /* Increase font size */
      text-align: left;
      color: #fff;
      background-color: transparent;
      border: none;
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    &-content {
      max-width: 770px; /* Adjust width to take up all available space */
      transition: height 0.25s cubic-bezier(0, 0, 0, 1); /* Use height for smoother animation */
      padding: 0.8rem; /* Increase padding for taller boxes */
      font-size: 1.8rem;
      line-height: 2rem;
      overflow: hidden; /* Ensure content doesn't overflow during animation */
    }

    &-panel {
      padding: 1.5rem; /* Increase padding for taller boxes */
    }
  }

  .chevron-down {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
    color: #fff;
  }

  &.szh-accordion__item--expanded {
    .szh-accordion__item-btn {
      background-color: rgba(255, 255, 255, 0.2);
      width: 100%;
    }
    .chevron-down {
      transform: rotate(180deg);
    }
  }
`;

export default function AccordionComponent() {
  return (
    <Wrapper>
      {/* `transitionTimeout` prop should be equal to the transition duration in CSS */}
      <Accordion transition transitionTimeout={250}>
        <AccordionItem header="WI-FI">
          WI-FI Name: <br/><br/>
          Password: 
        </AccordionItem>
        <AccordionItem header="Hot Tub">
          The cover to the hot tub is held down by a strap and lock. I should have texted the combination to the lock.<br/> <br/>

          The hot tub is serviced regularly to ensure that the water is sanitary and the water chemistry is optimum. In order to ensure that the water stays sanitary, always leave the floating bromine dispenser in the hot tub. Service is usually on Saturdays.<br /><br/>
          The only thing you might need to add to the water is Foam Down (the gray bottle with the blue cap). Because there are small amounts of soap and shampoo that don't get rinsed off when you shower and small amounts of detergent that doesn't get rinsed out of your bathing suit, eventually, you can get foam in the hot tub. A small squirt of Foam Down will quickly eliminate the foam.<br /><br/>

          Make sure you always latch the safety strap on the hot tub lid when the hot tub is not in use. The winds are regularly high enough to blow the cover off/open.<br /><br/>

          The hot tub temperature may have been turned down between guests.<br/> <br/>A comfortable temperature is between 100-103 degrees, slightly cooler in the summer. Do not press the button on the control panel that looks like a feather. It can put the spa in programming mode and result in unsafe conditions.
        </AccordionItem>

        <AccordionItem header="Trash & Recycling">
          Please place all trash on the back porch behind the sliding glass doors, and Recycling in the bins. Do not put any food or trash outside the house or in your vehicles overnight. Black bears are out and about from May to November.
        </AccordionItem>

        <AccordionItem header="Security Cameras">
          There are two security cameras at the house. One is on the lower corner of the deck facing the driveway. The other camera is under the outdoor stairs that go to the upper porch. The cameras cover the front door and corner of the hot-tub.<br />

          <br/>If this bothers you during your stay, you can block the cameras by placing a towel in front of it. Just remember to bring the towel back before the end of your stay.
        </AccordionItem>
      </Accordion>
    </Wrapper>
  );
}
