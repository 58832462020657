import React from "react";
import "../css/home.css";
import "../styleguide.css";
import { Link } from "react-router-dom";

export const App = () => {
  return (
    <div className="home-page">
      <div className="div" id="screen-wrapper">
        <div className="home-buttons">
          <Link onClick={() => window.open("https://maps.app.goo.gl/PfU3ZqKUpHLRGNq3A", "_blank")}>
          <div className="local-button">
            <div className="overlap-group">
              <div className="text-wrapper">In The Area</div>
              <img className="vector" alt="right_button" src="right_icon.svg" />
            </div>
          </div>
          </Link>
          <Link to="/nav">
          <div className="info-button">
            <div className="overlap">
              <div className="text-wrapper-2">Property Information</div>
              <img className="vector" alt="right_button" src="right_icon.svg" />
            </div>
          </div>
          </Link>
        </div>
        <div className="title-group">
          <div className="overlap-2">
            <div className="text-wrapper-3">Idaho Springs, CO, 80452</div>
            <div className="text-wrapper-4">381 Lake Rd.</div>
          </div>
        </div>
        <img className="cover-image" alt="Cabin" src="cover_image.png" />
      </div>
    </div>
  );
};
export default App;