import React from "react";
import "../css/amenities.css";
import "../styleguide.css";
import AccordionComponent from "./Accordion";
import { Link } from "react-router-dom";

export const Amenities = () => {
    return (
      <div className="amentiies-page">
        <div className="div">
          <Link to="/nav">
            <img className="icon-back" alt="Icon back" src="back_icon.svg" />
          </Link>
          <div className="amenity-header">
            <div className="section-title">Amenities</div>
          </div>
          <div className="centered-accordian-div">
            <AccordionComponent className="accordion-object" />
          </div>
        </div>
      </div>
    );
  };
  
