import React from "react";
import "../css/contact.css";
import "../styleguide.css";
import { Link } from "react-router-dom";

export const Contact = () => {
  return (
    <div className="contact-page">
      <div className="div">
        <Link to="/nav">
        <img className="icon-back" alt="Icon back" src="back_icon.svg" />
        </Link>
        <div className="contact-header">
          <div className="section-title">Contact</div>
        </div>
        <div className="contact-section">
          <div className="contact-description">
            <div className="contact-name">Rick</div>
            <p className="contact-number">(720) - 883 - 3524</p>
          </div>
        </div>
        <p className="text-wrapper">
          For any questions or concerns during your stay feel free to reach out.
        </p>
      </div>
    </div>
  );
};

export default Contact;
