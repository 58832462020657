import React from "react";
import "../css/checkout.css";
import "../styleguide.css"
import {Link} from "react-router-dom";

export const Checkout = () => {
    return (
        <div className="checkout-page">
            <div className="div">
                <div className="checkout-section">
                    <div className="checkout-header">Checking Out</div>
                    <p className="description-box">
                        We would greatly appreciate it if you could assist us with the following tasks before leaving.
                        <br />
                        <br />
                        1. Please place all dishes in the dishwasher and start the dishwasher. This allows our housekeeper to put
                        the dishes away while she cleans the house.
                        <br />
                        <br />
                        2. Place any used towels on the floor in front of the washing machine.
                        <br />
                        <br />
                        3. If you used the sleeper sofa, please place the sheets in front of the washing machine as well.
                        <br />
                        <br />
                        4. Please set the thermostat to 65F when you leave.
                        <br />
                        <br />
                        5. Please place all trash in bags in the grey trash can and recyclables in the blue bins in the back porch
                        through the sliding glass doors Do not leave any trash on the front porch as it will attract bears.
                        <br />
                        <br />
                        6. Please ensure the locking strap is in place across the top of the hot tub and locked. <br />
                        <br />
                        Thanks again, for your patronage and we hope you enjoy your visit! If you have any issues whatsoever or need
                        assistance with anything, please de not hesitate to call us.
                    </p>
                </div>
                <img className="icon-exit" alt="Icon exit" src="/icon_exit.svg" />
                <Link to="/nav">
                <img className="icon-back" alt="Icon back" src="/back_icon.svg" />
                </Link>
            </div>
        </div>
    );
};
export default Checkout;