import React from "react";
import "../css/sub.css";
import "../styleguide.css"
import {Link} from "react-router-dom"

export const SubPage = () => {
    return (
        <div className="nav-page">
            <div className="div">
                <div className="button-group">
                    <Link to="/rules">
                    <div className="rules-button">
                        <div className="overlap-group">
                            <div className="text-wrapper">House Rules</div>
                            <img className="vector" alt="Vector" src="right_icon.svg" />
                        </div>
                    </div>
                    </Link>
                    <Link to="/amenities">
                    <div className="amenities-button">
                        <div className="overlap-group">
                            <div className="text-wrapper">Amenities</div>
                            <img className="vector" alt="Vector" src="right_icon.svg" />
                        </div>
                    </div>
                    </Link>
                    <Link to="/checkout">
                    <div className="checkout-button">
                        <div className="overlap-group-2">
                            <div className="text-wrapper-2">Check-Out</div>
                            <img className="vector" alt="Vector" src="right_icon.svg" />
                        </div>
                    </div>
                    </Link>
                    <Link to="/contact">
                    <div className="contact-button">
                        <div className="local-button">
                            <div className="overlap-group-2">
                                <div className="text-wrapper-3">Contact</div>
                                <img className="vector" alt="Vector" src="right_icon.svg" />
                            </div>
                        </div>
                    </div>
                    </Link>
                </div>
                <Link to="/">
                <img className="icon-chevron-circle" alt="back_buton" src="back_icon.svg" />
                </Link>
            </div>
        </div>
    );
};

export default SubPage;
